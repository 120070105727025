import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Aquest bolet presenta un capell de pla a convex de fins a 12 cm de diàmetre que es va aixecant una mica el marge. Té una coloració ocre a marró i a vegades marró grisenc, que en freqüència es clivella on s’observa al davall una capa rogenca. Davall el capell es troba una capa de tubs de fins 1 cm de llargs primer de coloració groguenca i després groc verdós i blavosos si se’ls toca. Peu llarg en proporció al capell, de 5 a 10 cm, cilíndric de color groc i amb freqüència marcat de fibrilles longitudinals o amb taquetes roges sobretot a la part central. Les espores són de color oliva obscur en massa, fusiformes, de 12-16 x 4-6 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      